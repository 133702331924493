import axiosCMS             from '../../services/axiosCMS.js';
import json_languages       from '../../data/languages.json';
import json_business_types  from '../../data/business-type.json';
import { arrayHelpers }     from '../../constants/config.js';

export default {
    state : () => ({
        db_countries		    : [],
        db_languages		    : json_languages,
        db_business_types	    : json_business_types,
        db_reviews	            : [],
        brands_element          : [],
        catalogs_element        : [],
        list_cateogry_articles  : null,
        item_article            : null,
        agent                   : null
    }),
    mutations: {
        setBrandsElement(state, payload) {
            state.brands_element = payload
        },
        setCatalogsElement(state, payload) {
            state.catalogs_element = payload
        },
        setListCategoryArticles(state, payload) {
            console.log( payload );
            state.list_cateogry_articles = payload
        },
        setItemArticle(state, payload) {
            state.item_article = payload
        },
        setCountries(state, payload) {
            state.db_countries = payload;
        },
        setReviews(state, payload) {
            state.db_reviews = payload;
        },
        setAgent(state, payload) {
            state.agent = payload;
        }
    },
    getters: {
        getterBrandsElement     : state => state.brands_element,
        getterCatalogsElement   : state => state.catalogs_element,
        getterListCategoryArticles         : state => state.list_cateogry_articles,
        getterItemArticle          : state => state.item_article,
        getterCountries			: state => state.db_countries,
        getterLanguages			: state => state.db_languages,
        getterBusinessTypes     : state => state.db_business_types,
        getterReviews           : state => state.db_reviews,
        getterAgent             : state => state.agent
    },
    actions: {
        // =================================================================
        //  GENERAL ACTIONS
        // =================================================================
        
        async getCountries({commit}, payload) {
            
            await this.$hefesto_file.$get('/documents/json/store/countries.json').then( (axios_response) => {
                
                commit('setCountries', axios_response);
            });
        },
        async getReviews({commit}, payload) {
            
            await this.$hefesto_file.$get('/documents/json/store/reviews.json').then( (axios_response) => {
                
                commit('setReviews', axios_response);
            });
        },
        async getAgent({commit}, payload) {
            
            await this.$hefesto.$get(`/agents/find/${ payload.handle }`).then( (axios_response) => {
                
                const { data } = axios_response;
                commit('setAgent', data);
            });
        },
        // =================================================================
        //  HOME ACTIONS
        // =================================================================
        async getBrandsElment({ commit }) {
            
            await this.$hefesto.$get(`/front/slideshows/find/slider-brands?display_application=desktop_store`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setBrandsElement', data.images);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCatalogsElment({ commit }) {
            
            await this.$hefesto.$get(`/front/slideshows/find/slider-catalogs?display_application=desktop_store`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setCatalogsElement', data.images);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        // =================================================================
        //  BLOG ACTIONS
        // =================================================================
        async getListCategoryArticless({commit, state}, payload) {
            
            await this.$hefesto.$get(`/front/blog/category/list`).then( async (axios_response) => { 
                const { data } = axios_response;
                commit('setListCategoryArticles', data.reduce( (previous_item, current_item) => {
                    
                    let desktop_articles = arrayHelpers.chunkArray( JSON.parse( JSON.stringify( current_item.articles ) ), 3 );
                    let mobile_articles = JSON.parse( JSON.stringify( current_item.articles ) );
                    current_item.articles = {
                        desktop: desktop_articles,
                        mobile: mobile_articles
                    };
                    previous_item.push( current_item );
                    return previous_item;
                }, []) );
            }).catch((axios_error) => {
                
                commit('setListCategoryArticles', []);
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getItemBlog({commit, state}, payload) {
            
            await this.$hefesto.$get(`/front/blog/article/find/${ payload.handle }`).then( async (axios_response) => { 

                const { data } = axios_response;
                
                commit( 'setItemArticle', data );
                
            }).catch((axios_error) => {
                
                commit('setItemArticle', { message: "not found" });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        }
    }
}
